import React, { useState } from 'react'
import {
    BrowserRouter as Router,
    Switch, Route

} from "react-router-dom"

import Shopify from './Main/Shopify'
import Email from './Main/Email'
import Dashboard from './Main/Dashboard'
import Nav from './Nav/Nav'
import Readme from './Main/Readme'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../utils/firebase'

import { checkEmail, generateJS } from '../utils/requests'


const Generate = () => {

    const handleGenerate = async e => {
        const subButton = document.getElementById('generate')
        subButton.classList.toggle('submitting')
        const oldtext = subButton.innerText
        subButton.innerText = 'Saving...'

        const res = await generateJS()
        if (!res) {
            alert('Error generating new js file, contact tech')
        }

        subButton.classList.toggle('submitting')
        subButton.innerText = 'Saved!'

        setTimeout(() => {
            subButton.innerText = oldtext
        }, 2000)
    }

    return (
        <button id="generate" onClick={handleGenerate}>Save Ads</button>
    )
}
const Logout = () => {

    const handleLogout = e => {
        firebase.auth().signOut()
    }

    return (
        <button id="logout" onClick={handleLogout}>Logout</button>
    )
}
const TopNav = () => {
    return (
        <div className="top-nav">
            <Generate />
            <Logout />
        </div>
    )
}


const App = () => {
    const [signedIn, setSignIn] = useState(0)
    const [show, setShow] = useState(0)

    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false
        }
    }
    firebase.auth().onAuthStateChanged(async user => {
        if (user) {
            const exists = await checkEmail(user.email)
            exists ? setSignIn(1) : firebase.auth().signOut()
        }
        else {
            setSignIn(0)
        }
    })

    if (!signedIn) {
        return (
            <div id="no-signin">
                <div className="signin-container" onMouseOver={e => setShow(1)} onMouseLeave={e => setShow(0)}>
                    <div className="branding">
                        <div>
                            <span className="header">MRC</span>
                            <span className="sub-header">Intranet</span>

                        </div>
                        {/* <img className="logo" src="/static/ADN.svg" alt="MRC ADN Logo" /> */}
                        {/* <img className="harry img-fluid" src="/static/harry.png" alt="HAAARRRYYY" /> */}
                        {
                            show
                                ? <img className="harry" src="/static/harry.png" alt="HAAARRRYYY" style={{ height: '50px' }} />
                                : <img className="logo" src="/static/ADN.svg" alt="MRC ADN Logo" />
                        }
                    </div>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                </div>

            </div>
        )
    }
    return (
        <Router>
            <div id="app-wrapper">
                <Nav />
                <main id="app-main">
                    <div className="app-main--wrapper">
                        <TopNav />
                        <Switch>
                            <Route path="/shopify">
                                <Shopify />
                            </Route>
                            <Route path="/email">
                                <Email />
                            </Route>
                            <Route path="/readme">
                                <Readme />
                            </Route>
                            <Route path="/">
                                <Dashboard />
                            </Route>
                        </Switch>
                    </div>
                </main>
            </div>
        </Router>
    )

}

export default App;