import React, { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { POST_ROUTE } from '../../reducers/routeReducer'
import { saveEmails, getEmails } from '../../utils/requests'

const Email = () => {
    const dispatch = useDispatch()
    dispatch(POST_ROUTE('/email'))

    const [emailList, setEmailList] = useState('')


    useEffect(() => {

        const getEmailData = async () => {
            const data = await getEmails()
            setEmailList(data.join("\n"))
        }
        getEmailData()

    }, [])

    const handleSubmit = async () => {
        const subButton = document.getElementById('emailSubmit')
        subButton.classList.toggle('submitting')
        const oldtext = subButton.innerText
        subButton.innerText = 'Saving...'


        const resp = await saveEmails({ emailStr: emailList.trim() })
        if (!resp) {
            alert('some sort of error posting the new emails, contact tech')
        }

        subButton.innerText = 'Saved!'
        subButton.classList.toggle('submitting')
        
        setTimeout(() => {
            subButton.innerText = oldtext
        }, 2000)

    }

    return (
        <div id="email">
            <h1>Email</h1>
            <p>Please enter the emails of people who are allowed to use this tool.</p>
            <p>Put each email on its individual line.</p>
            <textarea spellCheck="false" cols="80" rows="10" id="emailList" value={emailList} onChange={e => setEmailList(e.currentTarget.value)}></textarea>
            <button id="emailSubmit" onClick={handleSubmit}>Save</button>
        </div>
    )
}
export default Email;