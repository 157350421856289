import React from 'react'

import { useDispatch } from 'react-redux'
import { POST_ROUTE } from '../../reducers/routeReducer'
const Shopify = () => {
    const dispatch = useDispatch()
    dispatch(POST_ROUTE('/shopify'))
    return (
            <h1>Shopify</h1>
    )
}
export default Shopify;