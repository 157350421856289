import React from 'react'
import { useSelector } from 'react-redux'
import AdForm from './AdForm'
import ShopifyForm from './ShopifyForm'

const AddForm = () => {

    let route = useSelector(state => state.route)
    if (route === "/") {
        return (
            <AdForm />
        )
    }
    else if (route === "/shopify") {
        return (
            <ShopifyForm />
        )
    }
    else{
        return (
            <div id="nothing"></div>
        )
    }
}
export default AddForm;