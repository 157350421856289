import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addAd, addSection, updateSection } from '../../utils/requests'

import { POST_AD } from '../../reducers/adReducer'
import { SET_SECTIONS, POST_SECTION } from '../../reducers/sectionReducer'
import { POST_MAXLEVEL } from '../../reducers/maxlevelReducer'
const AdForm = () => {
    const [tag, setTag] = useState("")
    const [link, setLink] = useState("")
    const [column, setColumn] = useState("")
    const [row, setRow] = useState("")
    const [image, setImage] = useState(null)

    let maxlevel = useSelector(state => state.maxlevel)
    let sections = useSelector(state => state.sections)

    const dispatch = useDispatch()



    const handleFileUpload = e => {
        setImage(e.target.files)
    }
    const handleFormSubmit = async e => {
        e.preventDefault()



        let formElements = document.getElementsByClassName('form--field')
        for (let i = 0; i < formElements.length; ++i) {
            if (formElements[i].classList.contains('error')) {
                formElements[i].classList.remove('error')
            }
        }

        let formError = 0

        if (!tag) {
            formError = 1
            document.getElementById('tag').classList.toggle('error')
        }
        if (!link) {
            formError = 1
            document.getElementById('link').classList.toggle('error')
        }
        if (!image) {
            formError = 1
            document.getElementById('image').classList.toggle('error')
        }
        if (!column) {
            formError = 1
            document.getElementById('col').classList.toggle('error')
        }
        if (!row) {
            formError = 1
            document.getElementById('roe').classList.toggle('error')
        }

        let btn = document.getElementById('adForm-submit')
        const oldBtnText = btn.innerText
        if (formError) {
            btn.classList.toggle('error')
            btn.innerText = "Error"
            setTimeout(() => {
                btn.classList.toggle('error')
                btn.innerText = oldBtnText
            }, 3000)
            return
        }

        btn.classList.toggle('uploading')
        btn.innerText = "Uploading..."


        const Data = new FormData();
        Data.append('tag', tag)
        Data.append('link', link)
        Data.append('file', image[0])
        Data.append('type', column)
        const res = await addAd(Data)
        if (res.status !== 201) {
            alert('error of some kind')
            return
        }

        console.log(res.data)

        dispatch(POST_AD(res.data))

        let section = sections.find(e => e.level === Number(row) && e.type === Number(column))

        if (section) {
            console.log(section)
            const sectionRes = await updateSection(section.id, { ad_id: res.data.id })
            if (sectionRes.status !== 204) {
                alert('section error, email tech')
                return
            }
            const newSections = [...sections.map(e => {
                if (e.level === Number(row) && e.type === Number(column)) {
                    e.ad_id = res.data.id
                    return e
                }
                else {
                    return e
                }
            })]
            dispatch(SET_SECTIONS(newSections))
        }
        else {
            const sectionData = {
                type: Number(column),
                level: Number(row),
                ad_id: res.data.id,
                weight: -1
            }

            const sectionRes = await addSection(sectionData)
            if (sectionRes.status !== 201) {
                alert('section error of some kind')
                return
            }
            dispatch(POST_SECTION(sectionRes.data))
            dispatch(POST_MAXLEVEL(Math.max(maxlevel, Number(row))))
        }

        btn.classList.toggle('uploading')
        btn.innerText = "Done!"

        setTimeout(() => {
            btn.innerText = oldBtnText
        }, 3000)

        setTag("")
        setLink("")
        setColumn("")
        setRow("")
        setImage(null)

        let form = document.getElementById('adForm')
        form.reset()

    }
    return (
        <form id="adForm" className="nav-form">
            <h1 className="form--header">
                Upload A New Ad
            </h1>
            <div id="tag" className="form--field">
                <label>Tag:</label>
                <input type="text" value={tag} onChange={e => setTag(e.currentTarget.value)}></input>
            </div>
            <div id="link" className="form--field">
                <label>Link:</label>
                <input type="text" value={link} onChange={e => setLink(e.currentTarget.value)}></input>
            </div>

            <div id="image" className="form--field">
                <label>Image:</label><input type="file" id="file-upload" onChange={handleFileUpload}></input>
            </div>

            <div id="col" className="form--field">
                <label>Column: </label>
                <select name="column" id="column" value={column} onChange={e => setColumn(e.currentTarget.value)}>
                    <option value="">Select...</option>
                    <option value="1">Banner Ad 1000x300</option>
                    <option value="2">Mid Size Ad 300x250</option>
                    <option value="3">Long Ad 300x600</option>
                </select>
            </div>
            <div id="roe" className="form--field">
                <label>Row: </label>
                <select name="row" id="row" value={row} onChange={e => setRow(e.currentTarget.value)}>
                    <option value="">Select...</option>
                    {
                        [...new Array(maxlevel + 1)].map((e, i) => {
                            const letter = String.fromCharCode(65 + i)
                            return (
                                <option key={i} value={i + 1}>{letter}</option>

                            )
                        })
                    }
                </select>
            </div>
            <button id="adForm-submit" className="nav-form--submit" onClick={handleFormSubmit}>Upload</button>
        </form>
    )
}
export default AdForm;