import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { POST_ROUTE } from '../../reducers/routeReducer'
import AddForm from '../Form/AddForm'

import { Link } from "react-router-dom"
const NavLink = ({ label, link }) => {
    let route = useSelector(state => state.route)
    let classes = route === link ? "menu-tab active" : "menu-tab"
    const dispatch = useDispatch()

    const handleLinkClick = e => {
        dispatch(POST_ROUTE(link))
    }

    return (
        <Link onClick={handleLinkClick} className={classes} to={link}>{label}</Link>
    )

}


const Nav = () => {

    const handleMenuClick = e => {
        let nav = document.getElementById('app-nav')
        nav.classList.toggle('closed')
        let main = document.getElementById('app-main')
        main.classList.toggle('expanded')



    }
    return (
        <nav id="app-nav">
            <div className="app-nav--wrapper">
                <div className="open-close-tab" onClick={handleMenuClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30px" height="30px"><path d="M0 0h24v24H0z" fill="none" /><path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" /></svg>
                </div>
                <div className="branding">
                    <img className="logo" src="/static/ADN.svg" alt="MRC ADN Logo" />
                    <span className="header">MRC</span>
                    <span className="sub-header">Intranet</span>
                </div>
                <div className="menu">
                    <NavLink label="Dashboard" link="/" />
                    {/* <NavLink label="Shopify" link="/shopify" /> */}
                    <NavLink label="Email" link="/email" />
                    <NavLink label="README.TXT" link="/readme" />
                </div>
                <div className="add-form-wrapper">
                    <AddForm />
                </div>
            </div>
        </nav>
    )
}
export default Nav;