import firebase from 'firebase';
import 'firebase/auth'

let firebaseConfig = {
    apiKey: "AIzaSyCanP7AWoCjS1R-KSGRlnFRfUiE90DsvJ0",
    authDomain: "mrc-intranet-d1c01.firebaseapp.com",
    databaseURL: "https://mrc-intranet-d1c01.firebaseio.com",
    projectId: "mrc-intranet-d1c01",
    storageBucket: "mrc-intranet-d1c01.appspot.com",
    messagingSenderId: "162545929456",
    appId: "1:162545929456:web:ee397c11f40a90e39f4635",
    measurementId: "G-GRE4GVLMG6"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase

