import React from 'react'

const Readme = () => {

    return (
        <div id="readme">
            <h1>README.TXT</h1>
            <h2>Dashboard</h2>
            <p>Simply upload ads using the form in the left hand side nav menu. All fields must be filled out. Each row is used to group a single campaign, represented by a letter. </p>
            <p>If you want to Alpha Beta Test different campaigns, simply click select on a different letter. Individual ads, or whole rows can be removed by clicking remove or delete row respectively. Clicking ‘Remove’ on an ad that is part of a campaign will simply return it to the selection table below. Clicking on the ‘X’ on an ad in the table will <strong>delete</strong> the ad from the database.</p>
            <p>You can change how likely a particular ad in a campaign is to be shown to the end user by adjusting each ads weight. <strong>Weights are calculated by column, not row.</strong> Each column must add up to 100. The default * character is a wildcard. The weight of and ad with ‘*’ will be evenly distributed between each ad with a *. Example: Ad A’s weight is 40 and Ad B/C’s weights are *, they will be distributed like so:</p>
            <p>A: 40<br />B: 30<br />C: 30</p>
            <p>When you are satisfied with your ad campaigns, and all the weights add up, <strong>Click ‘Save Ads’</strong> in the top right corner. This will generate the JavaScript file that gets sent out to all of our sites. You must <strong>Click ‘Save Ads’</strong> to save your changes.</p>
            <h2>Email</h2>
            <p>Maybe one day ill change this section to settings when there are more things to set – but for now its just email, so there you go.</p>
            <p>Add each email that you want to allow access to this app in the text box. No other accounts will be able to use the app.</p>
            <h2>README.TXT</h2>
            <p>You are here.</p>
        </div>
    )
}
export default Readme;