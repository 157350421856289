import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAd } from '../../utils/requests'
import { DELETE_AD } from '../../reducers/adReducer'
import { addSection, updateSection } from '../../utils/requests'
import { SET_SECTIONS, POST_SECTION } from '../../reducers/sectionReducer'
import { POST_MAXLEVEL } from '../../reducers/maxlevelReducer'

// Tag, add to, delete


const Ad = ({ ad }) => {

    const [show, setShow] = useState(0)

    const dispatch = useDispatch()
    let sections = useSelector(state => state.sections)
    let maxlevel = useSelector(state => state.maxlevel)


    const handleDelete = async (e, ad) => {
        const result = window.confirm(`Delete ad: ${ad.tag}?`)
        if (result) {
            const res = await deleteAd(ad.id)
            if (res.status !== 204) {
                alert('some sort of delete error, email tech')
                return
            }
            dispatch(DELETE_AD(ad.id))
        }
    }

    const handleAdd = async (e, level, ad) => {
        console.log(level, ad)

        let section = sections.find(e => e.level === Number(level) && e.type === Number(ad.type))

        if (section) {
            const sectionRes = await updateSection(section.id, { ad_id: ad.id })
            if (sectionRes.status !== 204) {
                alert('section error, email tech')
                return
            }
            const newSections = [...sections.map(e => {
                if (e.level === Number(level) && e.type === Number(ad.type)) {
                    e.ad_id = ad.id
                    return e
                }
                else {
                    return e
                }
            })]
            dispatch(SET_SECTIONS(newSections))
        }
        else {
            const sectionData = {
                type: Number(ad.type),
                level: Number(level),
                ad_id: ad.id,
                weight: -1
            }

            const sectionRes = await addSection(sectionData)
            if (sectionRes.status !== 201) {
                alert('section error of some kind')
                return
            }
            dispatch(POST_SECTION(sectionRes.data))
            dispatch(POST_MAXLEVEL(Math.max(maxlevel, Number(level))))
        }
    }
    return (
        <div key={ad.id} className="table--row" >
            <div className="tag" onMouseOver={e => setShow(1)} onMouseLeave={e => setShow(0)}>
                {ad.tag}
                {
                    show
                        ? <div className='ad-image-container'>
                            <img className="img-fluid" src={ad.s3url} alt={ad.tag} />
                        </div>
                        : false
                }
            </div>
            <div className="add">
                {
                    [...new Array(maxlevel + 1)].map((e, i) => {
                        const letter = String.fromCharCode(65 + i)
                        return (
                            <button key={i} onClick={e => handleAdd(e, i + 1, ad)} className="table-add"><span>{letter}</span></button>
                        )
                    })
                }
            </div>
            <div className="delete"><button className="table-delete" onClick={e => handleDelete(e, ad)}><span>X</span></button></div>
        </div>
    )
}

const TableColumn = ({ label, type }) => {
    let ads = useSelector(state => state.ads)
    let sections = useSelector(state => state.sections)
    let columnAds = ads.filter(e => {
        const adExist = sections.find(section => section.ad_id === e.id)
        if (e.type === type && !adExist) {
            return e
        }
        else {
            return 0
        }
    })



    return (
        <div className="table--column">
            <div className="table--row header">
                <div className="tag" >{label}</div>
                <div className="add">Add</div>
                <div className="delete">Delete</div>
            </div>
            {
                columnAds.map(ad => {
                    return (
                        <Ad key={ad.id} ad={ad} />
                    )
                })
            }
        </div>
    )
}

const Table = () => {
    return (
        <section id="ad_table">
            <div className="ad_table--wrapper">
                <TableColumn label="Banner Ad 1000x300" type={1} />
                <TableColumn label="Mid Size Ad 300x250" type={2} />
                <TableColumn label="Long Ad 300x600" type={3} />
            </div>
        </section>
    )
}
export default Table;