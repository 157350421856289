import { combineReducers } from 'redux'
import adReducer from './adReducer'
import routeReducer from './routeReducer'
import maxlevelReducer from './maxlevelReducer'
import sectionReducer from './sectionReducer'


export default combineReducers({
    ads: adReducer,
    route: routeReducer,
    maxlevel: maxlevelReducer,
    sections: sectionReducer
}) 