const sectionReducer = (state = [], action) => {
    switch (action.type) {
        case 'POST_SECTION':
            return [...state, action.data]
        case 'SET_SECTIONS':
            return [...action.data]
        case 'PUT_SECTION_WEIGHT':
            // return state.filter(elem => elem.id !== action.data)
            return [...state.map(e => {
                if (e.id === action.data.id) {
                    e.weight = action.data.weight
                    return e
                }
                return e
            })]
        case 'DELETE_SECTIONS':
            return state.filter(elem => elem.id !== action.data)
        default:
            return state
    }
}

export const POST_SECTION = (ad) => {
    return {
        type: 'POST_SECTION',
        data: ad
    }
}

export const PUT_SECTION_WEIGHT = (id, weight) => {
    return {
        type: 'PUT_SECTION_WEIGHT',
        data: { id, weight }
    }
}
export const SET_SECTIONS = (ads) => {
    return {
        type: 'SET_SECTIONS',
        data: ads
    }
}
export const DELETE_SECTION = (id) => {
    return {
        type: 'DELETE_SECTIONS',
        data: id
    }
}



export default sectionReducer