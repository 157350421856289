import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { POST_ROUTE } from '../../reducers/routeReducer'
import { SET_ADS } from '../../reducers/adReducer'
import { SET_SECTIONS, DELETE_SECTION, PUT_SECTION_WEIGHT } from '../../reducers/sectionReducer'
import { POST_MAXLEVEL } from '../../reducers/maxlevelReducer'
import { getAllAds, getAllSections, deleteSection, updateSection } from '../../utils/requests'

import Table from './Table'

const Section = ({ level, type }) => {
    const dispatch = useDispatch()
    let sections = useSelector(state => state.sections)
    let ads = useSelector(state => state.ads)
    let ready = 0
    let currAd, currSection = null
    if (sections.length && ads.length) {
        currSection = sections.find(e => e.type === type && e.level === level)
        if (currSection) {
            currAd = ads.find(e => e.id === currSection.ad_id)
            ready = 1
            // console.log(currAd)
        }

    }


    let label = ""
    if (type === 1) {
        label = "Banner Ad 1000x300"
    }
    else if (type === 2) {
        label = "Mid Size Ad 300x250"
    }
    else if (type === 3) {
        label = "Long Ad 300x600"
    }
    else {
        //should never reach here
    }

    const removeAd = async e => {
        const result = window.confirm(`Delete the ad from this section?`)
        if (result) {
            const res = await deleteSection(currSection.id)
            if (res.status !== 204) {
                alert('some sort of delete error, email tech')
                return
            }
            dispatch(DELETE_SECTION(currSection.id))
        }
    }

    const showWeight = () => {

        if (currSection.weight === -1) {
            return '*'
        }
        if (currSection.weight === 0) {
            return ''
        }
        else {
            return String(currSection.weight)
        }
    }

    const handleWeightChange = async e => {

        let value = String(e.currentTarget.value)
        value = value.trim()
        if (value.includes('*')) {
            value = -1
        }
        else if (value === '') {
            value = 0
        }
        else if (value.search(/[^\d]+/) >= 0) {
            // value = currSection.weight
            return
        }
        else if (Number(value) > 100) {
            value = currSection.weight
            return
        }
        else {
            value = Number(value)
        }
        dispatch(PUT_SECTION_WEIGHT(currSection.id, value))

        let type1total = 0,
            type2total = 0,
            type3total = 0
        let type1check = 0,
            type2check = 0,
            type3check = 0

        sections.forEach(elem => {
            switch (elem.type) {
                case 1:
                    if (elem.weight === -1) {
                        type1check = 1
                    }
                    else {
                        type1total += elem.weight
                    }
                    break
                case 2:
                    if (elem.weight === -1) {
                        type2check = 1
                    }
                    else {
                        type2total += elem.weight
                    }
                    break
                case 3:
                    if (elem.weight === -1) {
                        type3check = 1
                    }
                    else {
                        type3total += elem.weight
                    }
                    break


                default:
                //nothing...
            }
        })


        let ret = 0

        console.log('totals', type1total, type2total, type3total)
        console.log('checks', type1check, type2check, type3check)
        let type1inputs = document.getElementsByClassName('weight_1')
        let type2inputs = document.getElementsByClassName('weight_2')
        let type3inputs = document.getElementsByClassName('weight_3')

        for (let i = 0; i < type1inputs.length; ++i) {
            type1inputs[i].classList.remove('error')
        }
        for (let i = 0; i < type2inputs.length; ++i) {
            type2inputs[i].classList.remove('error')
        }
        for (let i = 0; i < type3inputs.length; ++i) {
            type3inputs[i].classList.remove('error')
        }



        if (type1total !== 100 && !type1check) {
            ret = 1
            for (let i = 0; i < type1inputs.length; ++i) {
                type1inputs[i].classList.toggle('error')
            }
        }
        if (type2total !== 100 && !type2check) {
            ret = 1
            for (let i = 0; i < type2inputs.length; ++i) {
                type2inputs[i].classList.toggle('error')
            }
        }
        if (type3total !== 100 && !type3check) {
            ret = 1
            for (let i = 0; i < type3inputs.length; ++i) {
                type3inputs[i].classList.toggle('error')
            }
        }

        if (ret) {
            return
        }

        sections.forEach(async section => {
            const sectionRes = await updateSection(section.id, { weight: section.weight })
            if (sectionRes.status !== 204) {
                alert('section error, email tech')
                return
            }
        })

    }

    return (
        <div className="section">
            <div className="label">{label}</div>
            {
                ready
                    ?
                    <>
                        <div className="tag">{currAd.tag}</div>
                        <div className="img-container">
                            <a href={currAd.link} target="_blank" rel="noopener noreferrer">
                                <img src={currAd.s3url} alt={label} className="section-img" />
                            </a>
                        </div>
                        <div className="ad-modifiers">
                            <div className="weight">
                                <label>Weight:</label>
                                <input type="text" className={`weight_${type}`} value={showWeight()} onChange={handleWeightChange}></input>
                            </div>
                            <button className="ad-remove" onClick={removeAd}>Remove</button>
                        </div>
                    </>
                    : <div className="noContent"><span>No Content</span></div>
            }



        </div>
    )
}

const AdLevel = ({ level }) => {
    level = Number(level)
    const dispatch = useDispatch()
    let sections = useSelector(state => state.sections)
    let maxlevel = useSelector(state => state.maxlevel)


    let letterCode = 64
    letterCode += level
    let rowLetter = String.fromCharCode(letterCode)
    const deleteRow = e => {
        let confirm = window.confirm(`Delete row ${rowLetter}?`)
        if (confirm) {
            let error = 0;
            sections.forEach(async elem => {
                if (elem.level === level) {
                    const res = await deleteSection(elem.id)
                    if (res.status !== 204) {
                        alert('error deleting a section, contact tech, refresh the page')
                        error = 1
                    }
                }

            })
            sections.forEach(async elem => {
                if (elem.level > level) {
                    const res = await updateSection(elem.id, { level: level })
                    if (res.status !== 204) {
                        alert('error updating a section, contact tech, refresh the page')
                        error = 1
                    }
                }

            })
            if (error) {
                return
            }
            const newSections = sections
                .filter(elem => elem.level !== level)
                .map(elem => {
                    if (elem.level > level) {
                        elem.level = elem.level - 1
                        return elem
                    }
                    return elem
                })


            dispatch(SET_SECTIONS(newSections))
            dispatch(POST_MAXLEVEL(--maxlevel))
        }
    }
    return (
        <div className="level">
            <div className="alpha"><span>{rowLetter}</span></div>
            <button onClick={deleteRow} className="deleteBtn">Delete Row</button>
            <Section level={level} type={1} />
            <Section level={level} type={2} />
            <Section level={level} type={3} />
        </div>
    )
}


const Dashboard = () => {
    const dispatch = useDispatch()
    dispatch(POST_ROUTE('/'))

    useEffect(() => {
        const getAdData = async () => {
            const data = await getAllAds()
            // setWeights(data)
            data ? dispatch(SET_ADS([...data])) : alert('Error loading data, first refresh then reach out to tech.')


        }
        getAdData()
        const getSectionData = async () => {
            const data = await getAllSections()
            // setWeights(data)
            data ? dispatch(SET_SECTIONS([...data])) : alert('Error loading data, first refresh then reach out to tech.')
            let max = 1
            data.forEach(elem => {
                max = Math.max(max, Number(elem.level))
            })

            dispatch(POST_MAXLEVEL(max))

        }
        getSectionData()



    }, [dispatch])

    let maxlevel = useSelector(state => state.maxlevel)





    return (
        <>
            <section id="ad_levels">
                {[...new Array(maxlevel)].map((e, i) => {
                    return (
                        <AdLevel key={i} level={i + 1} />
                    )
                })}
            </section>
            <Table />
        </>
    )
}
export default Dashboard;