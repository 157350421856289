import axios from 'axios'
let BaseAdUrl = 'http://localhost:5001/mrc-intranet-d1c01/us-central1/webApi/api/ads'
let BaseSectionUrl = 'http://localhost:5001/mrc-intranet-d1c01/us-central1/webApi/api/section'
let BaseGenerateUrl = 'http://localhost:5001/mrc-intranet-d1c01/us-central1/webApi/api/generate'
let BaseEmailUrl = 'http://localhost:5001/mrc-intranet-d1c01/us-central1/webApi/api/email'
if (process.env.NODE_ENV === 'production') {
    BaseAdUrl = 'https://us-central1-mrc-intranet-d1c01.cloudfunctions.net/webApi/api/ads'
    BaseSectionUrl = 'https://us-central1-mrc-intranet-d1c01.cloudfunctions.net/webApi/api/section'
    BaseGenerateUrl = 'https://us-central1-mrc-intranet-d1c01.cloudfunctions.net/webApi/api/generate'
    BaseEmailUrl = 'https://us-central1-mrc-intranet-d1c01.cloudfunctions.net/webApi/api/email'
}

// const checkPass = async pass => {
//     const response = await axios.post(`${BasePassCheck}${pass}`)
//     return (response.data.accept)
// }

const addAd = async (Data) => {
    const response = await axios.post(`${BaseAdUrl}`, Data, {
        headers: {
            'Accept': 'multipart/form-data',
            'Content-Type': 'multipart/form-data',
        }
    })
    return response
}
const addSection = async (Data) => {
    const response = await axios.post(`${BaseSectionUrl}`, Data)
    return response
}
const deleteAd = async (id) => {
    const response = await axios.delete(`${BaseAdUrl}/${id}`)
    return response
}
const deleteSection = async (id) => {
    const response = await axios.delete(`${BaseSectionUrl}/${id}`)
    return response
}
const updateSection = async (ID, Data) => {
    const response = await axios.put(`${BaseSectionUrl}/${ID}`, Data)
    return response
}

const getAllAds = async () => {
    const response = await axios.get(BaseAdUrl)
    return response.status === 200 ? response.data : 0

}
const getAllSections = async () => {
    const response = await axios.get(BaseSectionUrl)
    return response.status === 200 ? response.data : 0

}

const generateJS = async () => {
    const response = await axios.post(BaseGenerateUrl)
    return response.status === 201 ? 1 : 0
}

const saveEmails = async (data) => {
    const response = await axios.post(BaseEmailUrl, data)
    return response.status === 201 ? 1 : 0
}
const getEmails = async () => {
    const response = await axios.get(BaseEmailUrl)
    return response.status === 200 ? response.data : 0
}
const checkEmail = async (email) => {
    const response = await axios.get(`${BaseEmailUrl}/${email}`)
    return response.data.exists
}

export {
    addAd,
    addSection,
    getAllAds,
    getAllSections,
    updateSection,
    deleteAd,
    deleteSection,
    generateJS,
    saveEmails,
    getEmails,
    checkEmail
}