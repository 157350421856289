const routeReducer = (state = '/', action) => {
    switch (action.type) {
        case 'POST_ROUTE':
            return action.data
        default:
            return state
    }
}

export const POST_ROUTE = (route) => {
    return {
        type: 'POST_ROUTE',
        data: route
    }
}

export default routeReducer